export const defaultColorConfig = {
  mainThemeBackgroundColor: 'rgb(25, 34, 77)',
  secondaryThemeBackgroundColor: 'rgb(63, 81, 108)',
  color: 'rgb(255, 255, 150)',
  colorHighlighted: 'rgb(239, 7, 11)',
  colorSelected: 'rgb(80, 227, 194)',
  colorLocked: 'rgb(255, 255, 150)',
  lineWidth: '2',
  lineDash: '',
  shadow: true,
  textBoxVisibility: true,
  textBoxFontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
  textBoxFontSize: '14px',
  textBoxColor: 'rgb(255, 255, 150)',
  textBoxColorHighlighted: 'rgb(255, 255, 150)',
  textBoxColorSelected: 'rgb(255, 255, 150)',
  textBoxColorLocked: 'rgb(255, 255, 150)',
  textBoxBackground: '',
  textBoxLinkLineWidth: '1',
  textBoxLinkLineDash: '2,3',
  textBoxShadow: true,
};
