import React, { useEffect, useState, useRef } from "react";
import '../../../public/Css/login.css';
import { useNavigate } from "react-router-dom";
import { LoadingIndicatorProgress } from "@ohif/ui";
import Robotviet_WebLogo from '../../../../../platform/app/assets/Robotviet_WebLogo.png'; // Adjust the path as needed
import backgroundImage from '../../../../../platform/app/assets/1691726095693.jpg'; // Adjust the path as needed

import { checkVallidLoginTime, LoginApp, userUrlKey, useNamelKey, userTypeKey, lastLoginFailedCountKey, tokenKey, loginFailedCountKey } from "../../../src/Api/Apicntroller.js";
const Login = ({ }) => {
  //para
  const [error, setError] = useState("");
  let [checkinghistoryloging, setcheckhistoryLoging] = useState(true);
  const navigate = useNavigate();
  const [userLoginInfo, setUserInfo] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const returnUrl = useRef(localStorage.getItem(userUrlKey) ?? '');
  const patientid = useRef(localStorage.getItem(useNamelKey) ?? '');
  localStorage.removeItem(userUrlKey);
  localStorage.removeItem(useNamelKey);
  const [username, setUsername] = useState(patientid.current);
  const [password, setPassword] = useState("");
  useEffect(() => {
    if (patientid.current === null || patientid.current === undefined || patientid.current === '') {
      navigate('/notfoundstudy');
      return;
    }
  }, []);



  const handleLogin = async () => {
    if (!checkVallidLoginTime()) { setError("3 times login failed, please contact your admin and try agains 5 minute later !"); return; }
    if (password === null || password === undefined || password === '') {
      setError("Incorrect username or password.");
      return;
    }
    let user;
    try {
      setcheckhistoryLoging(true);
      user = await LoginApp(username, password);
    }
    catch (e) { }
    if ((user !== null) && ((user.AccessToken ?? '') !== '') && (user.AccessToken !== undefined)) {
      let token = user.AccessToken?.toString();
      localStorage.setItem(tokenKey, user.AccessToken ? '' : user.AccessToken?.toString());
      localStorage.setItem(userTypeKey, user.UserType ? '0' : user.UserType?.toString());
      localStorage.setItem(tokenKey, token);
      localStorage.setItem(userUrlKey, returnUrl.current.toString());
      localStorage.removeItem(loginFailedCountKey);
      if (((returnUrl.current ?? '') !== '') && ((patientid.current ?? '') !== '')) {
        if (patientid.current) {
          try {
            window.location.replace(returnUrl?.current);
            return;
          } catch (e) { }
        }
        else {
          navigate('/', { replace: true });
          return;
        }
      }
      else {
        navigate('/', { replace: true });
        return;
      }
    } else
      if (user != null) {
        try {
          let count = 0;
          try { count = parseInt(localStorage.getItem(loginFailedCountKey)) + 1; }
          catch (e) { }
          let currentTime = Date.now();
          localStorage.setItem(lastLoginFailedCountKey, currentTime.toString());
          localStorage.setItem(loginFailedCountKey, count.toString());
          setError(user.Message);
        }
        catch (e) { }
      }
      else setError('network error');;
    setcheckhistoryLoging(false);
  };

  //checkLoggedInHistory

  const checkLoggedInHistory = () => {
    try {
      let uif = localStorage.getItem(tokenKey);
      if (uif) {
        window.location.replace(returnUrl?.current);
        return;
      } else {
        setcheckhistoryLoging(false);
      }
    } catch (error) {
    }
    setcheckhistoryLoging(false);
  };
  useEffect(() => {
    checkLoggedInHistory();
  }, []);



  if (checkinghistoryloging) return (
    <div className="flex flex-col items-center justify-center pt-48">
      <LoadingIndicatorProgress className={'h-full w-full bg-black'} />
    </div>
  );

  return (
    <div className="mainlogin"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh', // Ensures the background covers the full viewport height
      }}
    >        <div className="login-container">
        <h2>Login</h2>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {error && <div className="error-message">{error}</div>}
        <button onClick={handleLogin}>Login</button>
      </div>
      <div style={{
        position: 'fixed',
        left: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center'
      }}>
        <img src={Robotviet_WebLogo} alt="Description of image" style={{ width: '150px', height: 'auto' }} />
        <span style={{ marginLeft: '10px', color: 'white' }}>DICOM VIEWER - Version 1.0</span>
      </div>
    </div>
  );
};

export default Login;
