import Buttons from './Buttons.json';
import CineDialog from './CineDialog.json';
import Common from './Common.json';
import Header from './Header.json';
import StudyList from './StudyList.json';
import UserPreferencesModal from './UserPreferencesModal.json';
import Color from './Color.json';
import AboutModal from './AboutModal.json';
import PatientInfo from './PatientInfo.json';
import SidePanel from './SidePanel.json';
import StudyBrowser from './StudyBrowser.json';
import Modes from './Modes.json';
export default {
  vi: {Modes,
    SidePanel,
    AboutModal,
    Color,
    Buttons,
    CineDialog,
    Common,
    Header,
    StudyList,
    UserPreferencesModal,
    PatientInfo,
    StudyBrowser
  },
};
