export const tokenKey = 'kjhlhbvjhgjhgug_token_hijhljpjpojpopoikpoi';
export const userTypeKey = 'wrwqerwqerwqerwqerwqerwqerwr456456465';
export const userUrlKey = 'erwerwerwerwerwerwerwerwerwer';
export const useNamelKey = '4634634634634634634634634634';
export const loginFailedCountKey = "werfwerwerwer34523423423423423";
export const lastLoginFailedCountKey = "345345345345345345345345345345";
export const shareQRtKey = "345345345sdfsdfsafasfa45345345345";

const host = //'http://localhost:12666/'
  'https://pacs-api-phunhuan.rveapp.com/';
const apikey = "0RjZ0dGxFNZUFpSVNVbjVnRzN3RIUi91aDBOZjQvQ3YwPQ==";
const apiUserNAme = 'PacsApi'
const apiPassword = 'TUZKcVdqQmtSM2hHVGpOU1NWVnBPVEZoUkE9PQ==';
const checkVallidLoginTime = () => {
  let currentTime = Date.now();
  const lockoutDuration = 0 * 60 * 1000; // 5 minutes in milliseconds
  let lastLoginTime = currentTime;
  try { lastLoginTime = parseInt(localStorage.getItem(lastLoginFailedCountKey)); }
  catch (e) { }
  let count = 0;
  try { count = parseInt(localStorage.getItem(loginFailedCountKey)); }
  catch (e) { }
  if (count < 3) { return true; }
  if ((currentTime - lastLoginTime) > lockoutDuration) {
    localStorage.removeItem(loginFailedCountKey);
    return true;
  }
  localStorage.setItem(lastLoginFailedCountKey, currentTime.toString());
  return false;
}

const LoginApp = async (username, password) => {
  const raw = JSON.stringify({
    "username": username,
    "password": password
  });

  try {
    const myHeaders = new Headers();
    myHeaders.append("api_key", apikey);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', 'Basic ' + btoa(apiUserNAme + ":" + apiPassword));

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: raw
    };

    const url = host + 'Pacs/login';
    const response = await fetch(url, requestOptions);
    if (response.status === 401) return { Status: 4, Message: 'Tài khoản hoặc mật khẩu không chính xác!Vui lòng kiểm tra lại.' }
    const result = await response.json();
    return result;
  } catch (error) {
  }
  return null;
};


const fetchStudyList = async (patientid, deviceID) => {
  try {
    let token = "";
    try { token = (localStorage.getItem(tokenKey).toString() ?? ''); }
    catch (e) { }
    const myHeaders = new Headers();
    myHeaders.append("api_key", apikey);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', 'Basic ' + btoa(apiUserNAme + ":" + apiPassword));
    myHeaders.append('client-security-token', 'Bearer ' + token);
    myHeaders.append('deviceClient', deviceID);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",

    };
    const response = await fetch(
      host + 'Dicomapi/GetStudies?patientid='
      + patientid
      , requestOptions);

    if (response.status === 401) { return { status: -2, data: null } }
    const result = await response.json();
    if (result && result.status === -2) {
      return { status: -2, data: null }
    }
    if (result)
      return result;
    return { status: 0, data: null }
  } catch (error) {
    return { status: -1, data: null }
  }
};

const fetchStudyLists = async (patientids) => {
  try {
    let token = "";
    try { token = (localStorage.getItem(tokenKey).toString() ?? ''); }
    catch (e) { }
    const myHeaders = new Headers();
    myHeaders.append("api_key", apikey);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', 'Basic ' + btoa(apiUserNAme + ":" + apiPassword));
    myHeaders.append('client-security-token', 'Bearer ' + token);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",

    };
    const response = await fetch(
      host + 'Dicomapi/GetStudies_V3?patientIds='
      + patientids
      , requestOptions);

    if (response.status === 401) { return { status: -2, data: null } }
    const result = await response.json();
    if (result && result.status === -2) {
      return { status: -2, data: null }
    }
    if (result)
      return result;
    return { status: 0, data: null }
  } catch (error) {
    return { status: -1, data: null }
  }
};

const GeneratePublicPatientUrl = async (duration) => {
  try {
    const raw = JSON.stringify({
      "duration": duration,
    });
    let token = "";
    try { token = (localStorage.getItem(tokenKey).toString() ?? ''); }
    catch (e) { }
    const myHeaders = new Headers();
    myHeaders.append("api_key", apikey);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', 'Basic ' + btoa(apiUserNAme + ":" + apiPassword));
    myHeaders.append('client-security-token', 'Bearer ' + token);

    const requestOptions = {
      method: "Post",
      headers: myHeaders,
      redirect: "follow",
      body: raw
    };
    const response = await fetch(
      host + 'Dicomapi/GeneratePublicPatientUrl', requestOptions);

    if (response.status === 401) { return { status: -2, message: "forbiden" } }
    const result = await response.json();
    if (result) {
      return { status: 1, data: result.toString(), message: "" }
    }
  } catch (error) {
  }
  return { status: 0, message: "lỗi, vui lòng thử lại" }
};


const LogOut = async () => {
  try {
    let token = "";
    try { token = (localStorage.getItem(tokenKey).toString() ?? ''); }
    catch (e) { }
    const myHeaders = new Headers();
    myHeaders.append("api_key", apikey);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', 'Basic ' + btoa(apiUserNAme + ":" + apiPassword));
    myHeaders.append('client-security-token', 'Bearer ' + token);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",

    };
    const response = await fetch(
      host + 'Dicomapi/Logout'
      , requestOptions);

    if (response.status === 401) { return -2; }
    const result = await response.json();
    if (result && result === true) {
      return 1;
    }

    return 0;
  } catch (error) {
    return -1;
  }
};


const DeleteShreQR = async () => {
  try {
    let token = "";
    try { token = (localStorage.getItem(tokenKey).toString() ?? ''); }
    catch (e) { }
    const myHeaders = new Headers();
    myHeaders.append("api_key", apikey);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', 'Basic ' + btoa(apiUserNAme + ":" + apiPassword));
    myHeaders.append('client-security-token', 'Bearer ' + token);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",

    };
    const response = await fetch(
      host + 'Dicomapi/DeleteShareQR'
      , requestOptions);

    if (response.status === 401) { return -2; }
    const result = await response.json();
    if (result && result === true) {
      return 1;
    }

    return 0;
  } catch (error) {
    return -1;
  }
};
const logout = async () => {
  if (await LogOut() === 1) {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(userTypeKey);
    //  localStorage.removeItem(useNamelKey);
    localStorage.removeItem(userUrlKey);
    return true;
  }
  return false;
};

const getToken = () => {
  try {
    return localStorage.getItem(tokenKey);
  }
  catch (e) { }
  return '';
};


export { LoginApp, fetchStudyList, getToken, logout, checkVallidLoginTime, GeneratePublicPatientUrl, fetchStudyLists, DeleteShreQR };
