import { defaultColorConfig } from '../../../../app/public/config/defaultColorConfig';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';





function hexToRgb(hexObj) {
  const hex = hexObj.toString().replace(/^#/, '');
  const rHex = hex.substring(0, 2);
  const gHex = hex.substring(2, 4);
  const bHex = hex.substring(4, 6);
  const r = parseInt(rHex, 16);
  const g = parseInt(gHex, 16);
  const b = parseInt(bHex, 16);
  return `rgb(${r}, ${g}, ${b})`;
}
function rgbToHex(rgb) {
  const [r, g, b] = rgb.match(/\d+/g).map(Number);
  const rHex = r.toString(16).padStart(2, '0');
  const gHex = g.toString(16).padStart(2, '0');
  const bHex = b.toString(16).padStart(2, '0');

  const hexColor = `#${rHex}${gHex}${bHex}`;

  return hexColor;
}



const ColorPicker = ({ initialColor, onColorChange }) => {
  const [color, setColor] = useState(rgbToHex(initialColor));

  useEffect(() => {
    setColor(rgbToHex(initialColor));
  }, [rgbToHex(initialColor)]);
  const handleChange = (event) => {
    const newColor = event.target.value;
    setColor(newColor);
    onColorChange(newColor);
  };

  return (
    <input
      type="color"
      value={color}
      onChange={handleChange}
      style={{ width: '100px' }} // Set a consistent width for ColorPicker
    />
  );
};


const LineWidthInput = ({ initialLineWidth, onLineWidthChange }) => {
  const [lineWidth, setLineWidth] = useState(initialLineWidth);

  useEffect(() => {
    setLineWidth(initialLineWidth);
  }, [initialLineWidth]);

  const handleChange = (event) => {
    const newLineWidth = event.target.value.replace(/\D/g, ''); // Replace non-numeric characters with empty string
    setLineWidth(newLineWidth);
    onLineWidthChange(newLineWidth);
  };

  return (
    <input
      type="text"
      value={lineWidth}
      onChange={handleChange}
      style={{ width: '100px', border: '1px solid #ccc', borderRadius: '1px', padding: '5px', background: 'transparent' }} // Set transparent background color
      pattern="\d*"
    />
  );
};


const ColorCF = ({ onsave, onClose }) => {
  const { t } = useTranslation();



  const [editConfig, setEditConfig] = useState(() => {
    const storedConfig = localStorage.getItem('config');
    return storedConfig ? JSON.parse(storedConfig) : defaultColorConfig;
  });
  const reset = (event) => {
    setEditConfig(defaultColorConfig);
  };

  const handleConfigChange = (color, colorname) => {
    setEditConfig(prevConfig => ({
      ...prevConfig,
      [colorname]: hexToRgb(color)
    }));
  };
  const handleLineWidtChange = (width, name) => {
    editConfig[name] = width;
    setEditConfig(editConfig);
  };



  const Cancel = () => {
    onClose();
  };
  const saveConfigToLocalStorage = () => {
    console.log('..........................................');
    console.log(editConfig);

    localStorage.setItem('config', JSON.stringify(editConfig));
    window.location.reload();
  };

  if (editConfig === null) return <div>...............</div>;

  return (
    <div style={{ display: 'inline-block', width: '400px', padding: '10px', backgroundColor: rgbToHex(editConfig.mainThemeBackgroundColor ?? defaultColorConfig.mainThemeBackgroundColor) }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ width: '200px', display: 'inline-block' }}>{t('Color:MainThemeBackgroundColor')}:</span>
          <ColorPicker initialColor={editConfig.mainThemeBackgroundColor ?? defaultColorConfig.mainThemeBackgroundColor} onColorChange={(color) => handleConfigChange(color, "mainThemeBackgroundColor")} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ width: '200px', display: 'inline-block' }}>{t('Color:Color')}:</span>
          <ColorPicker initialColor={editConfig.color} onColorChange={(color) => handleConfigChange(color, "color")} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ width: '200px', display: 'inline-block' }}>{t('Color:ColorHighlighted')}:</span>
          <ColorPicker initialColor={editConfig.colorHighlighted} onColorChange={(color) => handleConfigChange(color, "colorHighlighted")} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ width: '200px', display: 'inline-block' }}>{t('Color:ColorSelected')}:</span>
          <ColorPicker initialColor={editConfig.colorSelected} onColorChange={(color) => handleConfigChange(color, "colorSelected")} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ width: '200px', display: 'inline-block' }}>{t('Color:ColorLocked')}:</span>
          <ColorPicker initialColor={editConfig.colorLocked} onColorChange={(color) => handleConfigChange(color, "colorLocked")} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px', marginTop: '5px' }}>
          <span style={{ width: '200px', display: 'inline-block' }}>{t('Color:LineWidth')}:</span>
          <LineWidthInput initialLineWidth={editConfig.lineWidth} onLineWidthChange={(lineWidth) => handleLineWidtChange(lineWidth, "lineWidth")} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
          <span style={{ width: '200px', display: 'inline-block' }}>{t('Color:TextBoxColor')}:</span>
          <ColorPicker initialColor={editConfig.textBoxColor} onColorChange={(color) => handleConfigChange(color, "textBoxColor")} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ width: '200px', display: 'inline-block' }}>{t('Color:TextBoxColorHighlighted')}:</span>
          <ColorPicker initialColor={editConfig.textBoxColorHighlighted} onColorChange={(color) => handleConfigChange(color, "textBoxColorHighlighted")} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ width: '200px', display: 'inline-block' }}>{t('Color:TextBoxColorSelected')}:</span>
          <ColorPicker initialColor={editConfig.textBoxColorSelected} onColorChange={(color) => handleConfigChange(color, "textBoxColorSelected")} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ width: '200px', display: 'inline-block' }}>{t('Color:TextBoxColorLocked')}:</span>
          <ColorPicker initialColor={editConfig.textBoxColorLocked} onColorChange={(color) => handleConfigChange(color, "textBoxColorLocked")} />
        </div>
      </div>
      <div style={{ marginTop: '10px' }}>
        <button className='colorcf-button' onClick={saveConfigToLocalStorage}>
          {t('Color:Save Config')}
        </button>
        <button className='colorcf-button' onClick={reset}>
          {t('Color:Reset')}
        </button>
        <button className='colorcf-button' onClick={Cancel}>
          {t('Color:Cancel')}
        </button>
      </div>
    </div>


  );
};

export default ColorCF;
