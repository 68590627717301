import React from 'react';
import classNames from 'classnames';

import Icon from '../Icon';
import ProgressLoadingBar from '../ProgressLoadingBar';

/**
 *  A React component that renders a loading indicator.
 * if progress is not provided, it will render an infinite loading indicator
 * if progress is provided, it will render a progress bar
 * Optionally a textBlock can be provided to display a message
 */
function LoadingIndicatorProgress({ className, textBlock, progress, percent }) {
  return (
    <div className="loading-container">
      <p>
        {'Loading '}
        {percent === null || percent === undefined || percent === 0 ? '...' : percent + " %"}
      </p>
    </div>
  );
}

export default LoadingIndicatorProgress;
