import CryptoJS from 'crypto-js';

let getLocalIP = () => {
  return new Promise((resolve, reject) => {
    const peerConnection = new RTCPeerConnection();
    const dataChannel = peerConnection.createDataChannel("");

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        const candidate = event.candidate.candidate;
        const ipMatch = candidate.match(/(\d{1,3}\.){3}\d{1,3}/);

        if (ipMatch) {
          resolve(ipMatch[0]);
          peerConnection.close();
        }
      }
    };

    peerConnection.createOffer()
      .then((offer) => peerConnection.setLocalDescription(offer))
      .catch((error) => reject(error));
  });
}


export const getDeviceInfo = () => {
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;
  const language = navigator.language;
  const screenResolution = `${window.screen.width}x${window.screen.height}`;
  const colorDepth = window.screen.colorDepth;
  const cpuCores = navigator.hardwareConcurrency || '';
  const deviceMemory = (navigator as any).deviceMemory || '';
  const maxTouchPoints = navigator.maxTouchPoints || '';
  getLocalIP().then(ip => console.log('Local IP Address: ', ip)).catch(console.error);
  // Canvas Fingerprinting
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  let canvasFingerprint = '';
  if (ctx) {
    ctx.textBaseline = 'top';
    ctx.font = '14px Arial';
    ctx.textBaseline = 'alphabetic';
    ctx.fillStyle = '#f60';
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = '#069';
    ctx.fillText('https://example.com', 2, 15);
    ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
    ctx.fillText('https://example.com', 4, 17);
    canvasFingerprint = canvas.toDataURL();
  }

  return {
    userAgent,
    platform,
    language,
    screenResolution,
    colorDepth,
    cpuCores,
    deviceMemory,
    maxTouchPoints,
    canvasFingerprint,
  };
};
