import PropTypes from 'prop-types';
import classnames from 'classnames';
const stickyClasses = 'sticky top-0';
const notStickyClasses = 'relative';
//'bg-secondary-dark z-20 border-black px-1 style={}',
import { annotation } from '@cornerstonejs/tools';
import React, { useState, useEffect } from 'react';

const NavBar = ({ className, children, isSticky }) => {
  const getThemeColorConfig = () => {
    try {
      return localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : annotation.config.style.config.default.global;
    }
    catch (e) { }
    return annotation.config.style.config.default.global;
  }
  const colorConfig = getThemeColorConfig();
  return (
    <div
      className={classnames(
        'z-20 border-black px-1 style={}',
        isSticky && stickyClasses,
        !isSticky && notStickyClasses,
        className
      )}
      style={{ backgroundColor: colorConfig.mainThemeBackgroundColor }}
    >
      {children}
    </div>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isSticky: PropTypes.bool,
};

export default NavBar;
