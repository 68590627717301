import dicomImageLoader from '@cornerstonejs/dicom-image-loader';
import dcmjs from 'dcmjs';

class FileLoader {
  fileType;
  loadFile(file, imageId) { }
  getDataset(image, imageId) { }
  getStudies(dataset, imageId) { }
}

const cacheImageToFile = async (imageBlob, filename) => {
  try {
    const file = new File([imageBlob], filename, { type: imageBlob.type });
    return file;
  } catch (error) {
    throw new Error('Error caching image to file');
  }
};

const fetchImage = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Failed to fetch image');
    }
    else { }
    const imageBlob = await response.arrayBuffer();
    const file = await cacheImageToFile(imageBlob, url);
    const fileLoaderService = new FileLoaderService(file);
    const imageId = fileLoaderService.addFile(file);
    const dicomJSONDataset = await fileLoaderService.getDataset(imageBlob, imageId);
    return dicomJSONDataset;
  } catch (error) {
  }
  return null;
};
export { fetchImage };



const DICOMFileLoader = new (class extends FileLoader {
  fileType = 'application/dicom';
  loadFile(file, imageId) {
    return dicomImageLoader.wadouri.loadFileRequest(imageId);
  }

  getDataset(image, imageId) {
    const dicomData = dcmjs.data.DicomMessage.readFile(image);

    const dataset = dcmjs.data.DicomMetaDictionary.naturalizeDataset(dicomData.dict);

    dataset.url = imageId;

    dataset._meta = dcmjs.data.DicomMetaDictionary.namifyDataset(dicomData.meta);

    dataset.AvailableTransferSyntaxUID =
      dataset.AvailableTransferSyntaxUID || dataset._meta.TransferSyntaxUID?.Value?.[0];

    return dataset;
  }
})();


class FileLoaderService extends FileLoader {
  fileType;
  loader;
  constructor(file) {
    super();
    const fileType = file && file.type;
    this.loader = this.getLoader(fileType);
    this.fileType = this.loader.fileType;
  }

  addFile(file) {
    return dicomImageLoader.wadouri.fileManager.add(file);
  }

  loadFile(file, imageId) {
    return this.loader.loadFile(file, imageId);
  }

  getDataset(image, imageId) {
    return this.loader.getDataset(image, imageId);
  }

  getLoader(fileType) {
    if (fileType === 'application/pdf') {
      return PDFFileLoader;
    } else {
      // Default to dicom loader
      return DICOMFileLoader;
    }
  }
}
const PDFFileLoader = new (class extends FileLoader {
  fileType = 'application/pdf';
  loadFile(file, imageId) {
    return dicomImageLoader.wadouri.loadFileRequest(imageId);
  }

  getDataset(image, imageId) {
    const dataset = {};
    dataset.imageId = image.imageId || imageId;
    return dataset;
  }
})();
